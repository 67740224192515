import React from "react";
import { useKeycloak } from "@react-keycloak/web";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import RequireAuth from "./RequireAuth";
import App from "./App";

// https://ui.dev/react-router-protected-routes-authentication

function AppRouter() {

    // eslint-disable-next-line no-unused-vars
    const {keycloak, initialized} = useKeycloak();
    if (!initialized) {
        return null;
    }

    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={
                        <RequireAuth>
                            <App/>
                        </RequireAuth>
                    }/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}
   
export default AppRouter;