import React, { useState, useCallback, useRef } from 'react';
import { useKeycloak } from "@react-keycloak/web";
import Map from './Map';
import ControlPanel from './ControlPanel'
import ButtonBar from './ButtonBar'
import SearchBar from './SearchBar'
import ToolBar from './ToolBar'
import PlotPanel from './PlotPanel'
import Legends from './Legends'
import './Map.css';

const RP_YEARS = [10, 25, 50, 100, 250, 500, 1000];
//const MARKER_COLOURS = ['blue', 'orange', 'green', 'red', 'purple', 'brown', 'pink', 'grey', 'olive', 'torquoise'];
const MARKER_COLOURS = ['#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99', '#e31a1c', '#fdbf6f', '#ff7f00', '#cab2d6', '#6a3d9a', '#ffff99', '#b15928']

export const DEFAULT_PERIL = 'TCWind';
export const DEFAULT_EPOCH = 'PresentDay';
export const DEFAULT_RETURN_PERIOD = '100years';
export const DEFAULT_EVENT_YEARS = [2021, 2021];
export const DEFAULT_LAYER_OPACITY = 0.5;
export const DEFAULT_BASEMAP = 'streets';

function layerName(layer) {
    if (!layer.perilRef.current || !layer.epochRef.current || !layer.returnPeriodRef.current) {
        return null;
    } else {
        return `Map_${layer.perilRef.current}_${layer.epochRef.current}_${layer.returnPeriodRef.current}`;
    }
}

function makeUrl(endpoint, token, peril, epoch, latitude, longitude, gate, radius) {

    let url_params = new URLSearchParams(
                        {
                            access_token: token,
                            peril: peril,
                            epoch: epoch,
                            lats: [latitude],
                            lons: [longitude],
                            radius_km: radius
                        }
                    )
    for (let i = 0; i < RP_YEARS.length; i++) {
        url_params.append('years', RP_YEARS[i])
    }

    const url = new URL(
        `https://api.reask.earth/v1/deepcyc/${endpoint}?` +
        url_params
    );

    return url;
}

function fixupApiResponse(res, tool, epoch, lat, lng) {

    // FIXME: don't use camel-case in dataset names
    let epoch_label = epoch === 'PresentDay' ? 'Present Day' : epoch.replaceAll('_', ' ');

    let new_res = {
        id: `${epoch_label} (${lat.toFixed(2)}, ${lng.toFixed(2)})`,
        data: []
    }

    let windspeed_varname = 'windspeed_ft_3sec_kph'
    if (tool === 'gateep') {
        windspeed_varname = 'windspeed_nt_1min_kph'
    }

    for (let i = 0; i < res['data'].length; i++) {
        new_res['data'].push({x: res['data'][i]['return_period_year'],
                              y: res['data'][i][windspeed_varname]})
    }

    return new_res;
}

function App() {

    const {keycloak, } = useKeycloak();
    //const keycloak = {token: null};
    
    const [plotData, setPlotData] = useState([]);
    const [tool, setTool] = useState('grab');
    const [cursor, setCursor] = useState('grab');
    const [layerOpacity, setLayerOpacity] = useState(DEFAULT_LAYER_OPACITY);

    // FIXME: how to get rid of all this A, B stuff
    const [eventYearsA, setEventYearsA] = useState(DEFAULT_EVENT_YEARS);
    const [eventYearsB, setEventYearsB] = useState(DEFAULT_EVENT_YEARS);
    const [historicalTracksA, setHistoricalTracksA] = useState(false);
    const [historicalTracksB, setHistoricalTracksB] = useState(false);
    const [historicalFootprintsA, setHistoricalFootprintsA] = useState(false);
    const [historicalFootprintsB, setHistoricalFootprintsB] = useState(false);

    const [basemap, setBasemap] = useState(DEFAULT_BASEMAP);
    const [searchLatLng, setSearchLatLng] = useState({lat: null, lng: null})
    const [clearSearchMarkers, setClearSearchMarkers] = useState(false);

    const markerColors = useRef([]);
    const [lastMarkerAdded, setLastMarkerAdded] = useState(null);

    const layerBEnabled = useRef(false);

    const toolRef = useRef();
    const radiusRef = useRef(50);
    const plotDataRef = useRef([]);

    const layerState = {
        HazardA: {
            perilRef: useRef(DEFAULT_PERIL), 
            epochRef: useRef(DEFAULT_EPOCH),
            returnPeriodRef: useRef(DEFAULT_RETURN_PERIOD)
        },
        HazardB: {
            perilRef: useRef(DEFAULT_PERIL), 
            epochRef: useRef(DEFAULT_EPOCH),
            returnPeriodRef: useRef(DEFAULT_RETURN_PERIOD)
        }
    };
    const [layerA, setLayerA] = useState(layerName(layerState.HazardA));
    const [layerB, setLayerB] = useState(layerName(layerState.HazardB));
    const [enableLayerA, setEnableLayerA] = useState(true);
    const [enableLayerB, setEnableLayerB] = useState(false);

    const searchLocationCallback = useCallback((latLng) => {
        setClearSearchMarkers(false);
        setSearchLatLng(latLng);
    })

    const enableLayerCallback = useCallback((e, tab_name) => {
        if (tab_name === 'HazardB') {
            setEnableLayerB(e);
            layerBEnabled.current = e;
        } else {
            setEnableLayerA(e);
        }
    }, []);

    const selectLayerCallback = useCallback((e, select_name, tab_name) => {

        if (select_name === 'Peril') {
            layerState[tab_name].perilRef.current = e.target.value;
        } else if (select_name === 'Epoch') {
            layerState[tab_name].epochRef.current = e.target.value;
        } else {
            layerState[tab_name].returnPeriodRef.current = e.target.value;
        }

        let new_layer = layerName(layerState[tab_name]);
        if (new_layer) {
            if (tab_name === 'HazardA') {
                setLayerA(new_layer);
            } else {
                setLayerB(new_layer);
            }
        }

    }, []);

    const historicalTracksCallback = useCallback((enabled, tabName) => {
        if (tabName === 'HazardA') {
            setHistoricalTracksA(enabled);
        } else if (tabName === 'HazardB') {
            setHistoricalTracksB(enabled);
        }
    }, []);

    const historicalFootprintsCallback = useCallback((enabled, tabName) => {
        if (tabName === 'HazardA') {
            setHistoricalFootprintsA(enabled);
        } else if (tabName === 'HazardB') {
            setHistoricalFootprintsB(enabled);
        }
    }, []);

    const eventYearSliderCallback = useCallback((years, tabName) => {
        if (tabName === 'HazardA') {
            setEventYearsA(years);
        } else if (tabName === 'HazardB') {
            setEventYearsB(years);
        }
    }, []);

    const changeTool = useCallback((e) => {
        if (e.currentTarget.value === 'delete') {
            setPlotData([]);
            setLastMarkerAdded({});
            setClearSearchMarkers(true);
            markerColors.current = [];
            plotDataRef.current = [];
        } else {
            if (e.currentTarget.value === 'gateep' || e.currentTarget.value === 'pointep') {
                setCursor('crosshair')
            } else {
                setCursor('grab')
            }
            toolRef.current = e.currentTarget.value
            setTool(e.currentTarget.value)
        }
    }, []);

    const changeRadius = useCallback((e) => {
        radiusRef.current = e.currentTarget.value
    }, []);

    const changeBasemapCallback = useCallback((e) => {
        setBasemap(e.currentTarget.value);
    }, []);

    const changeOpacityCallback = useCallback((value) => {
        setLayerOpacity(value);
    }, []);

    const mapClickCallback = useCallback((e) => {

        if (toolRef.current === 'pointep' || toolRef.current === 'gateep') {

            const perilA = layerState['HazardA'].perilRef.current;
            const epochA = layerState['HazardA'].epochRef.current;
            let perilB = null;
            let epochB = null;
            if (layerBEnabled.current) {
                perilB = layerState['HazardB'].perilRef.current
                epochB = layerState['HazardB'].epochRef.current;
            }

            let i = markerColors.current.length;
            markerColors.current.push(i);

            let colorB = null
            if (perilB === null) {
                colorB = null
            } else if ((perilB === perilA) && (epochB === epochA)) {
                colorB = MARKER_COLOURS[i]
            } else {
                colorB = MARKER_COLOURS[i+1]
                markerColors.current.push(i+1);
            }

            setLastMarkerAdded({'colorA': MARKER_COLOURS[i],
                                'colorB': colorB,
                                'lat': e.lngLat.lat,
                                'lng': e.lngLat.lng});

            const url = makeUrl(toolRef.current, keycloak.token, perilA, epochA,
                                 e.lngLat.lat, e.lngLat.lng, 'circle', radiusRef.current);

            fetch(
                url,
                {
                    method: "GET",
                    headers: new Headers({
                        Accept: "application/json"
                    })
                }
            )
            .then(res => res.json())
            .then(res => fixupApiResponse(res, toolRef.current, epochA, e.lngLat.lat, e.lngLat.lng))
            .then(res => plotDataRef.current = plotDataRef.current.concat(res))
            .then(res => { setPlotData(res); })
            .catch(error => console.log(error));

            if (perilB && ((perilA !== perilB) || (epochA !== epochB))) {
                const url = makeUrl(toolRef.current, keycloak.token, perilB, epochB,
                                    e.lngLat.lat, e.lngLat.lng, 'circle', radiusRef.current);

                fetch(
                    url,
                    {
                        method: "GET",
                        headers: new Headers({
                            Accept: "application/json"
                        })
                    }
                )
                .then(res => res.json())
                .then(res => fixupApiResponse(res, toolRef.current, epochB, e.lngLat.lat, e.lngLat.lng))
                .then(res => plotDataRef.current = plotDataRef.current.concat(res))
                .then(res => { setPlotData(res); })
                .catch(error => console.log(error));
            }
        }

    }, [keycloak.token]);

    return (
        <div>
            <div>
                <Map
                    cursor={cursor}
                    basemap={basemap}
                    searchLatLng={searchLatLng}
                    layerA={layerA}
                    layerB={layerB}
                    enableLayerA={enableLayerA}
                    enableLayerB={enableLayerB}
                    eventYearsA={eventYearsA}
                    eventYearsB={eventYearsB}
                    historicalTracksA={historicalTracksA}
                    historicalTracksB={historicalTracksB}
                    historicalFootprintsA={historicalFootprintsA}
                    historicalFootprintsB={historicalFootprintsB}
                    mapClickCallback={mapClickCallback}
                    layerOpacity={layerOpacity}
                    lastMarkerAdded={lastMarkerAdded}
                    clearSearchMarkers={clearSearchMarkers}/>
            </div>
            <div className="tabPanelStyle">
                <ControlPanel
                    enableLayerCallback={enableLayerCallback}
                    selectLayerCallback={selectLayerCallback}
                    eventYearSliderCallback={eventYearSliderCallback}
                    historicalTracksCallback={historicalTracksCallback}
                    historicalFootprintsCallback={historicalFootprintsCallback}/>
            </div>
            <div className="logoStyle">
                <a href="https://reask.earth">
                    <img src="/Reask_Logo_Horizontal_RGB.png" alt="Reask"/>
                </a>
            </div>
           <div className="toolBarStyle">
                <ToolBar tool={tool} changeTool={changeTool} changeRadius={changeRadius} />
            </div>
           <div className="plotPanelStyle">
                <PlotPanel tool={tool} plotData={plotData}/>
            </div>
            <div className="legendsStyle"> 
                <Legends
                    historicalTracksA={historicalTracksA}
                    historicalTracksB={historicalTracksB}
                />
            </div>
            <div className="buttonBarStyle">
                <ButtonBar
                    changeBasemapCallback={changeBasemapCallback}
                    changeOpacityCallback={changeOpacityCallback}
                />
            </div>
            <div className="searchBarStyle">
                <SearchBar
                    searchLocationCallback={searchLocationCallback}
                 />
            </div>
         </div>
    );
}

export default App;
