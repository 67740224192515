
import React, { useState, useCallback } from 'react';
import { useKeycloak } from "@react-keycloak/web";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import OpacityOutlined from '@mui/icons-material/OpacityOutlined';
import LayersOutlined from '@mui/icons-material/LayersOutlined';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import LogoutRounded from '@mui/icons-material/LogoutRounded';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { DEFAULT_LAYER_OPACITY } from './App';

export default function ButtonBar({changeBasemapCallback, changeOpacityCallback}) {

    const {keycloak, initialized} = useKeycloak();
    //const keycloak = {token: null};
    //const initialized = true;

    const [opacity, setOpacity] = useState(DEFAULT_LAYER_OPACITY);
    const sliderCallback = useCallback((e) => {
        setOpacity(e.target.value);
        changeOpacityCallback(e.target.value);
    }, [changeOpacityCallback]);

    const logoutCallback = useCallback(() => {
        if (initialized) {
            keycloak.logout()
        }
    }, [initialized, keycloak]);

    return (
        <Stack spacing={1.0} direction="column" alignItems="center">
            <IconButton sx={{backgroundColor: "white"}} onClick={() => logoutCallback()}>
                <LogoutRounded/>
            </IconButton>
            <PopupState variant="popover" popupId="map-layers-popover">
                {(popupState) => (
                    <div>
                        <IconButton sx={{backgroundColor: "white"}} {...bindTrigger(popupState)}>
                            <InfoOutlined/>
                        </IconButton>
                        <Popover
                            {...bindPopover(popupState)}
                            anchorReference="anchorPosition"
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                            anchorPosition={{ top: 100, left: 2000}}
                        >
                            <Typography sx={{ p: 2 }}>The content of the Popover.</Typography>
                        </Popover>
                    </div>
                )}
            </PopupState>
            <PopupState variant="popover" popupId="map-layers-popover">
                {(popupState) => (
                    <div>
                        <IconButton sx={{backgroundColor: "white"}} {...bindTrigger(popupState)}>
                            <LayersOutlined/>
                        </IconButton>
                        <Popover
                            {...bindPopover(popupState)}
                            anchorReference="anchorPosition"
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                            anchorPosition={{ top: 148, left: 2000}}
                        >
                            <ToggleButtonGroup
                                exclusive
                                onChange={(e) => changeBasemapCallback(e)}
                                sx={{ backgroundColor: 'white' }}
                                aria-label="map selection"
                            >
                                <ToggleButton aria-label="dark" sx={{ backgroundColor: 'white'}}
                                    value='dark'
                                >
                                    Dark
                                </ToggleButton>
                                <ToggleButton aria-label="streets" sx={{ backgroundColor: 'white'}}
                                    value='streets'
                                >
                                    Light
                                </ToggleButton>
                                <ToggleButton aria-label="satellite" sx={{ backgroundColor: 'white'}}
                                    value='satellite'
                                >
                                    Satellite
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Popover>
                    </div>
                )}
            </PopupState>
            <PopupState variant="popover" popupId="opacity-popover">
                {(popupState) => (
                    <div>
                        <IconButton sx={{backgroundColor: "white"}} {...bindTrigger(popupState)}>
                            <OpacityOutlined/>
                        </IconButton>
                        <Popover
                            {...bindPopover(popupState)}
                            anchorReference="anchorPosition"
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                            anchorPosition={{ top: 196, left: 2000}}

                        >
                            <Box sx={{ width: "180px", pt: "10px", pb: "2px", pl: "10px", pr: "10px" }}>
                                <Slider
                                size="small"
                                value={opacity}
                                min={0.1}
                                max={1.0}
                                step={0.1}
                                aria-labelledby="track-inverted-slider"
                                onChange={(e) => sliderCallback(e)}
                                defaultValue={DEFAULT_LAYER_OPACITY}
                                />
                            </Box>
                        </Popover>
                    </div>
                )}
            </PopupState>
        </Stack>
    );
}