
import React from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./Keycloak";
import AppRouter from "./AppRouter";

/*
            <ReactKeycloakProvider authClient={keycloak}>
                <AppRouter />
            </ReactKeycloakProvider>
*/

function Start() {

    return (
        <div>
            <ReactKeycloakProvider authClient={keycloak}>
                <AppRouter />
            </ReactKeycloakProvider>
        </div>
    );
}
   
export default Start;