import React, { useState, useCallback, useRef } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined'
import DownloadOutlined from '@mui/icons-material/DownloadOutlined'
import PanToolOutlined from '@mui/icons-material/PanToolOutlined'
import InputAdornment from '@mui/material/InputAdornment';
import AddLocationAltOutlined from '@mui/icons-material/AddLocationAltOutlined'
import AddCircleOutlineOutlined from '@mui/icons-material/AddCircleOutlineOutlined'
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';

/*
                    <ToggleButton aria-label="pan and zoom" sx={{ backgroundColor: 'white'}}
                    <ToggleButton aria-label="point ep" sx={{ backgroundColor: 'white'}}
                <Box sx={{ width: '100%', backgroundColor: "white", borderRadius: '6px'}}>
                </Box>
                    */

export default function ToolBar({tool, changeTool, changeRadius}) {

    return (
        <Stack spacing={1} direction="row">
            <ToggleButtonGroup
                exclusive
                value={tool}
                sx={{ backgroundColor: 'white' }}
                onChange={(e) => changeTool(e)}
                aria-label="tool selection"
            >
                <ToggleButton aria-label="pan and zoom" sx={{ backgroundColor: 'white'}}
                    value='grab'
                >
                    <PanToolOutlined/>
                </ToggleButton>
                <ToggleButton aria-label="point ep" sx={{ backgroundColor: 'white'}}
                    value='pointep'
                >
                    <AddLocationAltOutlined/>
                </ToggleButton>
                <ToggleButton aria-label="gate ep" sx={{ backgroundColor: 'white'}}

                    value='gateep'
                >
                    <AddCircleOutlineOutlined/>
                </ToggleButton>
            </ToggleButtonGroup>

            <TextField
                label="radius"
                id="outlined-start-adornment"
                sx={{ width: '13ch', backgroundColor: 'white', borderRadius: '2px' }}
                variant="filled"
                size="small"
                onChange={changeRadius}
                defaultValue={50}
                InputProps={{
                    endAdornment: <InputAdornment position="end">km</InputAdornment>,
                }}
            />
            <ToggleButtonGroup
                sx={{ backgroundColor: 'white' }}
            >
                <ToggleButton aria-label="download" sx={{ backgroundColor: 'white' }}
                    value='download'
                    onClick={(e) => changeTool(e)}
                >
                    <DownloadOutlined />
                </ToggleButton>
                <ToggleButton aria-label="delete" sx={{ backgroundColor: 'white' }}
                    value='delete'
                    onClick={(e) => changeTool(e)}
                >
                    <DeleteOutlined />
                </ToggleButton>
            </ToggleButtonGroup>
        </Stack>
    );
}