import { useEffect, useState, useMemo, useRef, Fragment } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import throttle from 'lodash/throttle';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { MAPBOX_ACCESS_TOKEN } from './Map';
import './SearchBar.css';

const autocompleteService = { current: null };

export default function SearchBar({searchLocationCallback}) {
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const loaded = useRef(false);
    const locationFeatures = useRef([]);

    const throttled_fetch = useMemo(
        () =>
            throttle((req, callback) => {
                fetch (
                    `https://api.mapbox.com/geocoding/v5/mapbox.places/${req.input}.json?autocomplete=true&access_token=${MAPBOX_ACCESS_TOKEN}`,
                    {
                        method: "GET",
                        headers: new Headers({
                            Accept: "application/json"
                        })
                    }
                )
                .then(res => res.json())
                .then(res => callback(res))
                .catch((error) => console.log(error));
            }, 300),
        [],
    );

    useEffect(() => {

        if (!value) return;

        // See whether it is a place
        for (let i = 0; i < locationFeatures.current.length; i++) {
            if (value === locationFeatures.current[i]['place_name']) {
                const lat = locationFeatures.current[i].center[1];
                const lng = locationFeatures.current[i].center[0];
                searchLocationCallback({lat: lat, lng: lng});
                return;
            }
        }

        // See whether it is a lat, lng coord.
        let coords = value.split(',');
        if (coords.length !== 2) {
            coords = value.trim().split(/\s+/)
        } 

        if (coords.length === 2) {
            const lat = parseFloat(coords[0]);
            const lng = parseFloat(coords[1]);

            if (!isNaN(lat) && !isNaN(lng)) {
                if (lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180) {
                    searchLocationCallback({lat: lat, lng: lng});
                }
            }
        }

    }, [value]);

    useEffect(() => {
        let active = true;

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        throttled_fetch({ input: inputValue }, (res) => {

            if (active) {
                let newOptions = [];

                if (res) {
                    locationFeatures.current = res['features']
                    for (let i = 0; i < res['features'].length; i++) {
                        newOptions.push(res['features'][i]['place_name'])
                    }
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, throttled_fetch]);

    return (
        <Autocomplete
            id="mapbox-geocoding"
            sx={{ width: 360 }}
            filterOptions={(x) => x}
            options={options}
            freeSolo
            autoComplete
            includeInputInList
            value={value}
            onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Search"
                    size="small"
                    sx={{ backgroundColor: 'white', borderRadius: '4px' }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <Fragment>
                            {value ? params.InputProps.endAdornment :
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>}
                          </Fragment>
                         )
                    }}
                />
            )}
        />
    );
}