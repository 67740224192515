
import React, { useState, useCallback } from 'react';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import EpLinePlot from './EpLinePlot'


export default function PlotPanel({tool, plotData}) {

    return (
        <div>
            <Drawer
                PaperProps={{
                    sx: { width: "435px", height: "430px", top: "25%" },
                }}
                BackdropProps={{ invisible: true }}
                anchor={'right'}
                variant="persistent"
                style={{ overflow: "hidden" }}
                open={ tool === 'pointep' || tool === 'gateep' }
            >
                <Box sx={{ width: "425px", height: "360px", top: "60px" }}>
                     <Typography align='center' sx={{ p: 1.5 }}>Exceedence Probability Curve</Typography>
                     <EpLinePlot plotData={plotData}/>
                </Box>
            </Drawer>
        </div>
    );
}
