import { ResponsiveLine } from '@nivo/line'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export default function EpLinePlot({plotData}) {

    return (
        <ResponsiveLine
            data={plotData}
            colors={{ scheme: 'paired' }}
            margin={{ top: 10, right: 30, bottom: 50, left: 60 }}
            gridXValues={[10, 25, 50, 100, 250, 500, 1000]}
            xScale={{ 
                type: 'log', 
                base: 10,
                min: 10,
                max: 1000
            }}
            yScale={{
                type: 'linear',
                min: 60,
                max: 340,
            }}
            yFormat=" >-.2f"
            axisBottom={{
                tickValues: [10, 25, 50, 100, 250, 500, 1000],
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Return Period (years)',
                legendOffset: 36,
                legendPosition: 'middle'
            }}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Wind Speed (km/h)',
                legendOffset: -40,
                legendPosition: 'middle'
            }}
            animate={true}
            enableSlices='x'
            useMesh={true}
       />
    );
}