import { useRef, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";

function RequireAuth({ children }) {

    const containerRef = useRef();
    const {keycloak, initialized} = useKeycloak();

    useEffect(() => {
        if (!isLoggedIn()) {
            keycloak.login();
        }                
    }, [containerRef.current]);

    function isLoggedIn() {
        return (initialized && keycloak.authenticated)
    }

    return (
        isLoggedIn() ? children : <div ref={containerRef}/>
    );
}


export default RequireAuth;