
import React, { useState, useEffect, useRef, useCallback } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import * as d3 from 'd3';
import { legendColor, legendHelpers } from 'd3-svg-legend';

export default function Legends({historicalTracksA, historicalTracksB}) {

    const layerLegendRef = useCallback(node => {
        if (node === null) {
            return;
        }

        var ordinal = d3.scaleOrdinal()
            .domain([65, 60, 55, 50, 40, 30, 20])
            .range(['#ff255b', '#e1ee1c', '#bff037', '#80d941','#099fdd','#2f3290', '#000025']);

        const svg = d3.select(node);

        svg.append("g")
            .attr("class", "legendOrdinal")
            .attr("transform", "translate(20,20)")

        var legend = legendColor()
            .shapePadding(4)
            .labels(['250 kph', '', '200', '', '100', '', '0'])
            .shapeWidth(12)
            .shapeHeight(12)
            .scale(ordinal);

        svg.select(".legendOrdinal")
            .call(legend);

    }, []);

    const trackLegendRef = useCallback(node => {
        if (node === null) {
            return;
        }

        var ordinal = d3.scaleOrdinal()
            .domain(['H5', 'H4', 'H3', 'H2', 'H1', 'TS', 'TD'])
            .range(['#BB00F8', '#E50AAB', '#E43C20', '#FB7329', '#FDC33A', '#6FC351', '#2952F8']);

        const svg = d3.select(node);

        svg.append("g")
            .attr("class", "legendOrdinal")
            .attr("transform", "translate(20,20)");

        var legendOrdinal = legendColor()
            .shapePadding(4)
            .labels(['H5', 'H4', 'H3', 'H2', 'H1', 'TS', 'TD'])
            .shapeRadius(6)
            .shape('circle')
            .scale(ordinal);

        svg.select(".legendOrdinal")
            .call(legendOrdinal);

    }, []);
                    //<svg visible={historicalTracksA || historicalTracksB} ref={trackLegendRef} className="trackLegend"/>

    return (
            <Stack spacing={1} direction="row" alignItems="center">
                <Box sx={{ height: "160px", width: "40px", pt: "16px", pb: "10px", pl: "10px", pr: "10px" }}>
                    <svg visibility={(historicalTracksA || historicalTracksB) ? "visible" : "hidden"} ref={trackLegendRef} className="trackLegend"/>
                </Box>
                <Box sx={{ height: "160px", width: "40px", pt: "10px", pb: "10px", pl: "10px", pr: "10px" }}>
                    <svg ref={layerLegendRef} className="layerLegend"/>
                </Box>
            </Stack>
    );
}
