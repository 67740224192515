import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
/*
import { MapboxStyleSwitcherControl } from "mapbox-gl-style-switcher";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
*/
import MapboxCompare from 'mapbox-gl-compare';
import mapboxgl from 'mapbox-gl';
import _ from 'lodash';

import "mapbox-gl-style-switcher/styles.css";
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'mapbox-gl-compare/dist/mapbox-gl-compare.css';
import './Map.css';

import { DEFAULT_LAYER_OPACITY, DEFAULT_BASEMAP } from './App';
import { map } from 'd3';

export const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoicmVhc2ttYXBwIiwiYSI6ImNsNHJ2b256azAzMHgzYnRhaWFtZzJ2cWsifQ.peNy_s77SgxnYIrdICDz-Q'

// https://stackoverflow.com/questions/65802002/mapbox-production-error-in-console-uncaught-referenceerror-y-is-not-defined
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

// We have this bug I think
// https://github.com/mapbox/mapbox-gl-js/issues/8480


mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

const LAYER_PAINT =
    {'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'ws'],
        0, '#000025', 
        18, '#2f3290',
        33, '#099fdd',
        43, '#80d941',
        50, '#bff037',
        58, '#e1ee1c',
        70, '#ff255b'],
    'fill-opacity': 0.5,
    'fill-antialias': false
    }

const FOOTPRINT_PAINT = 
    {
        'fill-color': [
            'interpolate',
            ['linear'],
            ['get', 'ws'],
            0, '#000025', 
            63, '#2f3290',
            119, '#099fdd',
            154, '#80d941',
            178, '#bff037',
            209, '#e1ee1c',
            252, '#ff255b'
        ],
        'fill-opacity': 0.5,
        'fill-antialias': false
    }


const EVENT_POINT_PAINT = 
    {
    'circle-color': [
        'match', 
        ['get', 'category'],
        'TD', '#2952F8', 
        'TS', '#6FC351',
        'H1', '#FDC33A',
        'H2', '#FB7329',
        'H3', '#E43C20',
        'H4', '#E50AAB',
        'H5', '#BB00F8',
        '#2952F8'
    ],
    'circle-radius': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        10.0,
        5.0
    ],
    'circle-opacity': 0.75
    }

const EVENT_LINE_PAINT = 
    {
        'line-color': [
            'match', 
            ['get', 'category'],
            'TD', '#2952F8', 
            'TS', '#6FC351',
            'H1', '#FDC33A',
            'H2', '#FB7329',
            'H3', '#E43C20',
            'H4', '#E50AAB',
            'H5', '#BB00F8',
            '#2952F8'
        ],
        'line-width': 1.0,
        'line-opacity': 0.75
    }

const IBTRACS_START_YEAR = 1980;
const IBTRACS_END_YEAR = 2022;

function event_popup_html(track_name, date, Vm, Cp) {
    return `
        <h5 style="font-weight:bold;color:whitesmoke;"><u>${track_name}</u></h5>
        <h5 style="font-weight:bold;color:whitesmoke;">Date</h5>
        <p>${date}</p>
        <h5 style="font-weight:bold;color:whitesmoke;">Wind speed</h5> 
        <p>${Vm} km/h</p>
        <h5 style="font-weight:bold;color:whitesmoke;">Pressure</h5>
        <p>${Cp} hPa</p>
     `;
};

function layer_popup_html(ws) {
    return `
        <p>${ws} km/h</p>
     `;
};


function classifyWindspeed(ws) {
    let category = null;
    if (ws <= 50.0) {
        return 'layer_popup-td';
    } else if (ws <= 100.0) {
        return 'layer_popup-ts';
    } else if (ws <= 150.0) {
        return 'layer_popup-h1';
    } else if (ws <= 175.0) {
        return 'layer_popup-h2';
    } else if (ws <= 200.0) {
        return 'layer_popup-h3';
    } else if (ws <= 225.0) {
        return 'layer_popup-h4';
    } else {
        return 'layer_popup-h5';
    }
}


let selectedTcId = null;
let selectedTcSourceLayer = null;
let curPopupClassname = null;
let curLayerPopupClassname = null;
const event_popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false
});

/*
const debouncePopupRemove = useMemo(
    () => debounce(point_popup.remove, 3000)
)
*/

// Example code here: https://docs.mapbox.com/help/tutorials/use-mapbox-gl-js-with-react/

const Map = (props) => {

    const comp_container = useRef(null);
    const compRef = useRef(null);
    const mapA_container = useRef(null);
    const mapA = useRef(null);
    const mapB_container = useRef(null);
    const mapB = useRef(null);

    const currLayerARef = useRef(null);
    const currLayerBRef = useRef(null);
    const enableLayerARef = useRef(true);
    const enableLayerBRef = useRef(false);

    const currHistoricalTracksARef = useRef(null);
    const currHistoricalTracksBRef = useRef(null);
    const currHistoricalFootprintsARef = useRef(null);
    const currHistoricalFootprintsBRef = useRef(null);
    const currEventYearsARef = useRef(null);
    const currEventYearsBRef = useRef(null);
    const currBasemap = useRef(null);
    const currMarkers = useRef([]);
    const currSearchMarkers = useRef([]);
    const addedPointLayers = useRef([]);

    const currFootprintNameA = useRef(null);
    const currFootprintNameB = useRef(null);

    const eventPopupActive = useRef(false);
    const layerPopupActive = useRef(false);

    const [mouseLng, setMouseLng] = useState(0);
    const [mouseLat, setMouseLat] = useState(0);
    const [centerLng, setCenterLng] = useState(0);
    const [centerLat, setCenterLat] = useState(0);
    const [zoom, setZoom] = useState(1.2);
    const [mouseZoom, setMouseZoom] = useState(1.0);

    const layerPopup = useRef(new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
        }));

    let checking_style_statusA = false;
    let checking_style_statusB = false;

    // FIXME: debounce these
    // https://blog.logrocket.com/how-and-when-to-debounce-or-throttle-in-react/
    //const throttled_add_layer_popup = useCallback(_.debounce(add_layer_popup, 100));
    function add_layer_popup(e) {
        if (!eventPopupActive.current) {
            if (e.features) {
                let ws = null;
                // FIXME: use the same units for all layers
                if (e.features[0].sourceLayer.substring(0, 4) === 'Map_') {
                    ws = Math.round(e.features[0].properties.ws * 3.6)
                } else {
                    ws = Math.round(e.features[0].properties.ws)
                }
                const description = layer_popup_html(ws);
                if (curLayerPopupClassname) {
                    layerPopup.current.removeClassName(curLayerPopupClassname);
                }
                curLayerPopupClassname = classifyWindspeed(ws);
                layerPopup.current.addClassName(curLayerPopupClassname);
                layerPopup.current.setLngLat(e.lngLat).setHTML(description).addTo(e.target);
            }
        }
    }

    function remove_layer_popup(e) {
        layerPopup.current.removeClassName(curLayerPopupClassname);
        layerPopup.current.remove();
    }

    function add_event_popup(e) {

        if (selectedTcId) {
            for (const pointLayer of addedPointLayers.current) {
                e.target.removeFeatureState({
                    source: 'IBTrACS_points_v2',
                    sourceLayer: selectedTcSourceLayer,
                    id: selectedTcId
                });
            }

            event_popup.removeClassName(curPopupClassname);
            event_popup.remove();
        }

        let feat = e.features[0];
        selectedTcId = feat.id;
        selectedTcSourceLayer = feat.sourceLayer;

        if ((currHistoricalFootprintsARef.current || currHistoricalFootprintsBRef.current)
             && (feat.properties.landfalling === "True")) {
            e.target.getCanvas().style.cursor = 'pointer';
            try {
                e.target.setFeatureState({
                    source: 'IBTrACS_points_v2',
                    sourceLayer: selectedTcSourceLayer,
                    id: selectedTcId
            }, { hover: true });
            } catch(err) {

            }
        }

        curPopupClassname = `popup-${feat.properties.category.toLowerCase()}`;
        event_popup.addClassName(curPopupClassname);
        const coordinates = feat.geometry.coordinates.slice();
        const description = event_popup_html(feat.properties.track_name,
                                    feat.properties.date,
                                    feat.properties.Vm,
                                    feat.properties.Cp);
        if (layerPopup.current) {
            layerPopup.current.remove();
        }
        event_popup.setLngLat(coordinates).setHTML(description).addTo(e.target);
        eventPopupActive.current = true;
    }

    function remove_event_popup(e) {
        e.target.getCanvas().style.cursor = ''
        if (selectedTcId) {
            e.target.removeFeatureState({
                source: 'IBTrACS_points_v2',
                sourceLayer: selectedTcSourceLayer,
                id: selectedTcId
            });

            event_popup.removeClassName(curPopupClassname);
            event_popup.remove();
            eventPopupActive.current = false;
        }
    }

    function add_footprint(map, map_name, currFootprintName, newFootprintName) {

        if (map_name === 'mapA') {
            if (!currHistoricalFootprintsARef.current) return;
        } else {
            if (!currHistoricalFootprintsBRef.current) return;
        }

        if (map.getLayer(currFootprintName)) {
            map.removeLayer(currFootprintName);
        }

        if (!map.getSource(newFootprintName)) {
            map.addSource(newFootprintName, {
                type: 'vector',
                url: `mapbox://reaskmapp.${newFootprintName}`
            });
        }
        if (!map.getLayer(newFootprintName)) {
            map.addLayer({
                'id': newFootprintName,
                'type': 'fill',
                'source': newFootprintName,
                'source-layer': newFootprintName,
                'layout': {
                    // Make the layer visible by default.
                    'visibility': 'visible',
                },
                'paint': FOOTPRINT_PAINT
            });
        }
    }

    function update_layer(map, newLayerName, currLayerName, enableLayer) {

        let visibility = 'none';
        if (enableLayer) {
            visibility = 'visible';
        }

        if (!newLayerName) return;
        if (!map.isStyleLoaded()) return;

        if (!map.getSource(newLayerName)) {
            map.addSource(newLayerName, {
                type: 'vector',
                url: 'mapbox://reaskmapp.' + newLayerName
            });
        }

        if (map.getLayer(newLayerName)) {
            map.setLayoutProperty(newLayerName, 'visibility', visibility);
        } else {
            map.addLayer({
                'id': newLayerName,
                'type': 'fill',
                'source': newLayerName,
                'source-layer': newLayerName,
                'layout': { 'visibility': visibility },
                'paint': LAYER_PAINT
            });
        }

        if (currLayerName) {
            map.setLayoutProperty(currLayerName, 'visibility', 'none');
        }

    };

    function update_events(map, map_name, historicalTracks, historicalFootprints, eventYears) {

        if (historicalTracks === null) return;
        if (!map.isStyleLoaded()) return;

        const feats = ['lines', 'points'];
        const selectedYears = _.range(eventYears[0], eventYears[1]+1);

        if (historicalTracks) {
            for (const feat of feats) {
                const sourceName = `IBTrACS_${feat}_v2`
                if (!map.getSource(sourceName)) {
                    // FIXME: make the id field uniform
                    let idField = 'my_id'
                    if (feat === 'lines') {
                        idField = 'sid'
                    }

                    map.addSource(sourceName, {
                        type: 'vector',
                        url: 'mapbox://reaskmapp.' + sourceName,
                        promoteId: idField
                    });
                }
            }

            for (let year = IBTRACS_START_YEAR; year <= IBTRACS_END_YEAR; year++) {

                for (const feat of feats) {
                    const sourceName = `IBTrACS_${feat}_v2`;
                    const eventsName = `IBTrACS_${feat}_${year}`;
                    const labelsName = `IBTrACS_symbols_${year}`;

                    if (selectedYears.includes(year)) {

                        if (map.getLayer(eventsName)) {
                            map.setLayoutProperty(eventsName, 'visibility', 'visible');
                            if (feat === 'lines') {
                                map.setLayoutProperty(labelsName, 'visibility', 'visible');
                            }

                        } else {
                            if (feat === 'points') {
                                map.addLayer({
                                    'id': eventsName,
                                    'type': 'circle',
                                    'source': sourceName,
                                    'source-layer': eventsName,
                                    'layout': {
                                        'visibility': 'visible'
                                    },
                                    'paint': EVENT_POINT_PAINT
                                });

                            } else if (feat === 'lines') {

                                map.addLayer({
                                    'id': eventsName,
                                    'type': 'line',
                                    'source': sourceName,
                                    'source-layer': eventsName,
                                    'layout': {
                                        'visibility': 'visible'
                                    },
                                    'paint': EVENT_LINE_PAINT
                                });

                                // Add storm names
                                map.addLayer({
                                    'id': labelsName,
                                    'type': 'symbol',
                                    'source': sourceName,
                                    'source-layer': eventsName,
                                    'layout': {
                                        'text-field': ['get', 'track_name'],
                                        'text-anchor': 'bottom',
                                        'text-radial-offset': 0.1,
                                        'text-size': 12,
                                        'text-padding': 100,
                                        'symbol-placement': 'line-center',
                                        'visibility': 'visible'
                                    },
                                });
                            }
                        }

                        if (feat === 'points') {
                            addedPointLayers.current.push(eventsName);
                        }
                    } else {
                        if (map.getLayer(eventsName)) {
                            map.setLayoutProperty(eventsName, 'visibility', 'none');
                            if (feat === 'lines') {
                                map.setLayoutProperty(labelsName, 'visibility', 'none');
                            }
                        }
                    }
                }
            }

            // FIXME: need to remove these event listeners
            map.on('mousemove', addedPointLayers.current, add_event_popup);
            map.on('mouseleave', addedPointLayers.current, remove_event_popup);

        } else {
            let removedLayers = [];
            for (let year = IBTRACS_START_YEAR; year <= IBTRACS_END_YEAR; year++) {
                for (const feat of feats) {
                    const labelsName = `IBTrACS_symbols_${year}`;
                    if (feat === 'lines') {
                        if (map.getLayer(labelsName)) {
                            map.setLayoutProperty(labelsName, 'visibility', 'none');
                        }
                    }

                    const eventsName = `IBTrACS_${feat}_${year}`
                    if (map.getLayer(eventsName)) {
                        map.setLayoutProperty(eventsName, 'visibility', 'none');
                        removedLayers.push(eventsName);
                    }
                }
            }
        }

        if (historicalFootprints) {
            map.on('click', addedPointLayers.current, (e) => {
                let sid = e.features[0].properties.sid;
                let newFootprintName = `FP_${sid}_FT_gust_v1`;
                if (map_name ==='mapA') {
                    add_footprint(map, map_name, currFootprintNameA.current, newFootprintName);
                    currFootprintNameA.current = newFootprintName;
                } else {
                    add_footprint(map, map_name, currFootprintNameB.current, newFootprintName);
                    currFootprintNameB.current = newFootprintName;
                }
            });

        } else {
            if (map_name ==='mapA') {
                if (map.getLayer(currFootprintNameA.current)) {
                    map.removeLayer(currFootprintNameA.current);
                    currFootprintNameA.current = null;
                }
            } else {
                if (map.getLayer(currFootprintNameB.current)) {
                    map.removeLayer(currFootprintNameB.current);
                    currFootprintNameB.current = null;
                }
            }                    
        }
    }

    // See: 
    // https://stackoverflow.com/questions/44394573/mapbox-gl-js-style-is-not-done-loading/57792470#57792470
    function refresh_all(map, map_name) {
        if (map.isStyleLoaded()) {
            if (map_name === 'mapA') {
                checking_style_statusA = false;
                update_layer(map, currLayerARef.current, null, enableLayerARef.current);
                update_events(map, map_name, currHistoricalTracksARef.current,
                              currHistoricalFootprintsARef.current,
                              currEventYearsARef.current);
            } else {
                checking_style_statusB = false;
                update_layer(map, currLayerBRef.current, null, enableLayerBRef.current);
                update_events(map, map_name, currHistoricalTracksBRef.current,
                              currHistoricalFootprintsBRef.current,
                              currEventYearsBRef.current);
            }
        } else {
            // If not yet loaded, repeat check after delay:
            setTimeout(function() {refresh_all(map, map_name);}, 200);
            return;
        }
    }

    // Enable comparison
    useEffect(() => {
        if (!mapA.current) return;
        if (!mapB.current) return;

        let enableCompare = props.enableLayerB || props.historicalTracksB

        if (enableCompare) {
            if (compRef.current) return;

            let center = mapA.current.getCenter();
            mapB.current.setCenter([center.lng, center.lat]);
            mapB.current.setZoom(mapA.current.getZoom());

            compRef.current = new MapboxCompare(mapA.current, mapB.current, comp_container.current);

            // FIXME: intermittend weird behaviour, something to do with mapB
            // center and zoom not being set properly before comparison?
            mapB.current.setCenter([center.lng, center.lat]);
            mapB.current.setZoom(mapA.current.getZoom());

        } else {
            if (!compRef.current) return;

            compRef.current.remove();
            compRef.current = null;
        }
    }, [props.enableLayerB, props.historicalTracksB]);


    // Load
    useEffect(() => {
        if (mapA.current) return;

        currBasemap.current = DEFAULT_BASEMAP;

        mapA.current = new mapboxgl.Map({
            container: mapA_container.current,
            style: `mapbox://styles/mapbox/${currBasemap.current}-v11`,
            zoom: zoom,
            center: [centerLng, centerLat]
        });

        mapB.current = new mapboxgl.Map({
            container: mapB_container.current,
            style: `mapbox://styles/mapbox/${currBasemap.current}-v11`,
            zoom: zoom,
            center: [centerLng, centerLat]
        });

        mapA.current.on('mousemove', (e) => {
            setMouseLng(e.lngLat.lng.toFixed(2));
            setMouseLat(e.lngLat.lat.toFixed(2));
        });

        currLayerARef.current = props.layerA;
        currLayerBRef.current = props.layerB;

        mapA.current.on('click', (e) => {
            props.mapClickCallback(e);
        });
        mapB.current.on('click', (e) => {
            props.mapClickCallback(e);
        });

        mapA.current.on('zoom', (e) => {
            let zoomLevel = mapA.current.getZoom().toFixed(1)
            setMouseZoom(zoomLevel)

            let target_layersA = [currLayerARef.current, currFootprintNameA.current];
            let target_layersB = [currLayerBRef.current, currFootprintNameB.current];

            // Add a popup with the windspeed of current cell.
            if (!layerPopupActive.current && zoomLevel >= 10) {
                mapA.current.on('mousemove', target_layersA, add_layer_popup);
                mapA.current.on('mouseleave', target_layersA, remove_layer_popup);
                mapB.current.on('mousemove', target_layersB, add_layer_popup);
                mapB.current.on('mouseleave', target_layersB, remove_layer_popup);

                layerPopupActive.current = true;
            } else if (layerPopupActive.current && zoomLevel < 10) {
                layerPopup.current.remove();
                mapA.current.off('mousemove', target_layersA, add_layer_popup);
                mapA.current.off('mouseleave', target_layersA, remove_layer_popup);
                mapB.current.off('mousemove', target_layersB, add_layer_popup);
                mapB.current.off('mouseleave', target_layersB, remove_layer_popup);
                layerPopupActive.current = false;
            }
        });

        /*
        mapB.current.on('zoom', (e) => {
            let zoomLevel = mapA.current.getZoom().toFixed(1)

            // Add a popup with the windspeed of current cell.
            if (!layerPopupActive.current && zoomLevel >= 10) {
                mapB.current.on('mousemove', currLayerARef.current, add_layer_popup);
                mapB.current.on('mouseleave', currLayerARef.current, remove_layer_popup);
                layerPopupActive.current = true;
            } else if (layerPopupActive.current && zoomLevel < 10) {
                console.log('calling layerPopup.remove()')
                layerPopup.current.remove();
                mapB.current.off('mousemove', currLayerARef.current, add_layer_popup);
                mapB.current.off('mouseleave', currLayerARef.current, remove_layer_popup);
                layerPopupActive.current = false;
            }
        });
        */

        mapA.current.on('styledata', (e) => {
            if (checking_style_statusA) {
                // If already checking style status, bail out
                // (important because styledata event may fire multiple times)
                return;
            } else {
                checking_style_statusA = true;
                refresh_all(mapA.current, 'mapA');
            }
        });
        mapB.current.on('styledata', (e) => {
            if (checking_style_statusB) {
                // If already checking style status, bail out
                // (important because styledata event may fire multiple times)
                return;
            } else {
                checking_style_statusB = true;
                refresh_all(mapB.current, 'mapB');
            }
        });

    }, []);

    // Change a layer
    useEffect(() => {
        if (!mapA.current) return;

        enableLayerARef.current = props.enableLayerA;
        update_layer(mapA.current, props.layerA, currLayerARef.current,
                     enableLayerARef.current);
        currLayerARef.current = props.layerA;

    }, [props.layerA, props.enableLayerA]);
    useEffect(() => {
        if (!mapB.current) return;

        enableLayerBRef.current = props.enableLayerB;
        update_layer(mapB.current, props.layerB, currLayerBRef.current,
                     enableLayerBRef.current);
        currLayerBRef.current = props.layerB;

    }, [props.layerB, props.enableLayerB]);

    // Add a marker
    useEffect(() => {
        if (!mapA.current) return;
        if (!mapB.current) return;
        if (!props.lastMarkerAdded) return;

        if (_.isEmpty(props.lastMarkerAdded)) {
            for (const marker of currMarkers.current) {
                marker.remove();
            }

            currMarkers.current = [];
        } else {

            const colorA = props.lastMarkerAdded['colorA'];
            const colorB = props.lastMarkerAdded['colorB'];
            const lat = props.lastMarkerAdded['lat'];
            const lng = props.lastMarkerAdded['lng'];

            const markerA = new mapboxgl.Marker({
                color: colorA,
                draggable: false
            }).setLngLat([lng, lat]).addTo(mapA.current);
            currMarkers.current.push(markerA);

            if (compRef.current && colorB) {
                const markerB = new mapboxgl.Marker({
                    color: colorB,
                    draggable: false
                }).setLngLat([lng,  lat]).addTo(mapB.current);
                currMarkers.current.push(markerB);
            }
        }

    }, [props.lastMarkerAdded]);


    // Show historical tracks
    useEffect(() => {
        if (!mapA.current) return;
        currHistoricalTracksARef.current = props.historicalTracksA;
        currHistoricalFootprintsARef.current = props.historicalFootprintsA;
        currEventYearsARef.current = props.eventYearsA;

        update_events(mapA.current, 'mapA', props.historicalTracksA, props.historicalFootprintsA, props.eventYearsA);
    }, [props.historicalTracksA, props.historicalFootprintsA, props.eventYearsA]);
    useEffect(() => {
        if (!mapB.current) return;
        currHistoricalTracksBRef.current = props.historicalTracksB;
        currHistoricalFootprintsBRef.current = props.historicalFootprintsB;
        currEventYearsBRef.current = props.eventYearsB;

        update_events(mapB.current, 'mapB', props.historicalTracksB, props.historicalFootprintsB, props.eventYearsB);
    }, [props.historicalTracksB, props.historicalFootprintsB, props.eventYearsB]);

    useEffect(() => {
        if (!mapA.current) return;
        if (!mapB.current) return;

        mapA.current.getCanvas().style.cursor = props.cursor
        mapB.current.getCanvas().style.cursor = props.cursor
    }, [props.cursor]);


    // Change the basemap
    useEffect(() => {
        if (!mapA.current && !mapB.current) return;
        if (currBasemap.current === props.basemap) return;

        let style = 'mapbox://styles/mapbox/streets-v11';
        if (props.basemap === 'satellite') {
            style = 'mapbox://styles/mapbox/satellite-v9';
        } else if (props.basemap === 'streets') {
            style = 'mapbox://styles/mapbox/streets-v11';
        } else if (props.basemap === 'dark') {
            style = 'mapbox://styles/mapbox/dark-v10';
        }

        if (mapA.current) {
            mapA.current = mapA.current.setStyle(style);
        }

        if (mapB.current) {
            mapB.current = mapB.current.setStyle(style);
        }

        currBasemap.current = props.basemap;

    }, [props.basemap]);


    // Change the layer opacity
    useEffect(() => {
        if (!mapA.current) return;
        if (!mapA.current.isStyleLoaded()) return;

        if (currLayerARef.current) {
            mapA.current.setPaintProperty(
                currLayerARef.current,
                'fill-opacity',
                props.layerOpacity
            );
        }

        if (currLayerBRef.current) {
            mapB.current.setPaintProperty(
                currLayerBRef.current,
                'fill-opacity',
                props.layerOpacity
            );
        }

    }, [props.layerOpacity]);

    // Move to search location
    useEffect(() => {
        const lat = props.searchLatLng['lat'];
        const lng = props.searchLatLng['lng'];

        if (lat === null) return;

        if (mapA.current) {
            const marker = new mapboxgl.Marker({
                color: 'red',
                draggable: false
            }).setLngLat([lng,  lat]).addTo(mapA.current);
            currSearchMarkers.current.push(marker);

            mapA.current.flyTo({
                center: [lng, lat],
                essential: true
            });
        }
        if (compRef.current && mapB.current) {
            const marker = new mapboxgl.Marker({
                color: 'red',
                draggable: false
            }).setLngLat([lng,  lat]).addTo(mapB.current);
            currSearchMarkers.current.push(marker);

            mapB.current.flyTo({
                center: [lng, lat],
                essential: true
            });
        }

    }, [props.searchLatLng]);

    // Clear search markers
    useEffect(() => {
        if (props.clearSearchMarkers) {
            for (const marker of currSearchMarkers.current) {
                marker.remove();
            }
            currSearchMarkers.current = [];
        }
    }, [props.clearSearchMarkers]);
 
    return (
        <div>
            <div className='sidebarStyle'>
                <div>
                    {mouseLat} {mouseLng} {mouseZoom}
                </div>
            </div>
            <div ref={comp_container}>
                <div ref={mapB_container} className='map-container'/>
                <div ref={mapA_container} className='map-container'/>
            </div>
        </div>
    );
};


export default Map;
