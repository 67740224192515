import React from 'react';
import ReactDOM from 'react-dom';
import { StyledEngineProvider } from '@mui/material/styles'
import './index.css';
import Start from './Start';

ReactDOM.render(
    <React.StrictMode>
        <StyledEngineProvider injectFirst>
            <Start />
        </StyledEngineProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
