import * as React from 'react';
import { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Slider from '@mui/material/Slider';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
/*
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
*/


import { DEFAULT_PERIL, DEFAULT_EPOCH, DEFAULT_RETURN_PERIOD, DEFAULT_EVENT_YEARS } from './App';

                /*
                <Grid item xs={12}>
                    <Typography gutterBottom>
                        Category
                    </Typography>
                    <Slider
                        aria-label="Custom marks"
                        defaultValue={6} 
                        getArialValueText={valuetext}
                        step={1}
                        track={false}
                        marks={category_marks}
                        min={0}
                        max={5}
                        valueLabelDisplay="auto"
                    />
                </Grid>
const category_marks = [
    {
        value: 0,
        label: 'TS',
    },
    {
        value: 1,
        label: 'H1',
    },
    {
        value: 2,
        label: 'H2',
    },
    {
        value: 3,
        label: 'H3',
    },
    {
        value: 4,
        label: 'H4',
    },
    {
        value: 5,
        label: 'H5',
    },
];

                */


const PerilSelect = ({name, enabled, selectLayerCallback}) => {
    return (
      <Box sx={{ minWidth: 120 }} >
        <FormControl fullWidth disabled={!enabled}>
          <InputLabel id="peril-simple-select-label">Peril</InputLabel>
          <Select
            labelId="peril-simple-select-label"
            id="peril-simple-select"
            defaultValue={DEFAULT_PERIL}
            label="Peril"
            onChange={(e) => selectLayerCallback(e, 'Peril', name)}
          >
            <MenuItem value={"TCWind"}>Tropical Cyclone Wind</MenuItem>
          </Select>
        </FormControl>
      </Box>
    );
}

const EpochSelect = ({name, enabled, selectLayerCallback}) => {
    return (
      <Box sx={{ minWidth: 120 }} >
        <FormControl fullWidth disabled={!enabled}>
          <InputLabel id="risk-simple-select-label">Epoch</InputLabel>
          <Select
            labelId="risk-simple-select-label"
            id="risk-simple-select"
            defaultValue={DEFAULT_EPOCH}
            label="Epoch"
            onChange={(e) => selectLayerCallback(e, 'Epoch', name)}
          >
            <MenuItem value={"PresentDay"}>Present Day</MenuItem>
            <MenuItem value={"June2022"}>Seasonal (June 2022)</MenuItem>
            <MenuItem value={"2035_SSP585"}>2035 (SSP5.8.5)</MenuItem>
            <MenuItem value={"2050_SSP585"}>2050 (SSP5.8.5)</MenuItem>
          </Select>
        </FormControl>
      </Box>
    );
}

const ReturnPeriodSelect = ({name, enabled, selectLayerCallback}) => {
    return (
      <Box sx={{ minWidth: 120 }} >
        <FormControl fullWidth disabled={!enabled}>
          <InputLabel id="rp-simple-select-label">Return Period</InputLabel>
          <Select
            labelId="rp-simple-select-label"
            id="rp-simple-select"
            defaultValue={DEFAULT_RETURN_PERIOD}
            label="Return Period"
            onChange={(e) => selectLayerCallback(e, 'Return_Period', name)}
          >
            <MenuItem value={"20years"}>20 years</MenuItem>
            <MenuItem value={"50years"}>50 years</MenuItem>
            <MenuItem value={"100years"}>100 years</MenuItem>
            <MenuItem value={"250years"}>250 years</MenuItem>
          </Select>
        </FormControl>
      </Box>
    );
}

/*
const UnitsSelect = (props) => {
    return (
        <Box sx={{ minWidth: 120 }} >
            <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">Windspeed Units</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                >
                    <FormControlLabel value="one_min_sustained" control={<Radio />} label="1-min sustained" />
                    <FormControlLabel value="three_second_gust" control={<Radio />} label="3-sec gust" />
                </RadioGroup>
            </FormControl>
        </Box>
    );
}
*/

function HazardInfoIcon() {
    return (
        <InfoOutlined sx={{ fontSize: 16 }}/>
    );
}

function HistoricalTracksInfoIcon() {
    return (
        <InfoOutlined sx={{ fontSize: 16 }}/>
    );
}

function HistoricalFootprintsInfoIcon() {
    return (
        <InfoOutlined sx={{ fontSize: 16 }}/>
    );
}

/*
function HistoricalTracksSwitch({toggleCallback}) {
    const [checked, setChecked] = useState(false);

    const switchHandler = (event) => {
        console.log('switchHandler called')
        console.log(toggleCallback)
        console.log(event)
        setChecked(event.target.checked);
    };

    return (
      <FormGroup>
        <FormControlLabel control={<Switch onChange={(e) => toggleCallback(e)} />} label="Show Historical Tracks" />
      </FormGroup>
    );
}

function HistoricalFootprintsSwitch({toggleCallback}) {
    return (
      <FormGroup>
        <FormControlLabel control={<Switch disabled={true} onChange={(e) => toggleCallback(e)} />} label="Show Historical Footprints" />
      </FormGroup>
    );
}
*/

function valuetext(value) {
    return `${value}°C`;
}

const marks = [
    {
        value: 1980,
        label: '1980',
    },
    {
        value: 1990,
        label: '1990',
    },
    {
        value: 2000,
        label: '2000',
    },
    {
        value: 2010,
        label: '2010',
    },
    {
        value: 2020,
        label: '2020',
    },
];


const enabled = 'disabled';


function HazardLayerSelect({name, enableLayerCallback, historicalTracksCallback, historicalFootprintsCallback,
                            eventYearSliderCallback, selectLayerCallback, ...rest}) {

    const [layerEnabled, setLayerEnabled] = React.useState(name === "HazardA" ? true : false);
    const [tracksEnabled, setTracksEnabled] = useState(false);
    const [footprintsEnabled, setFootprintsEnabled] = useState(false);
    const [eventYearsA, setEventYearsA] = useState(DEFAULT_EVENT_YEARS);
    const [eventYearsB, setEventYearsB] = useState(DEFAULT_EVENT_YEARS);

    const layerEnabledRef = useRef(name === "HazardA" ? true : false);
    const tracksEnabledRef = useRef(false);
    const footprintsEnabledRef = useRef(false);

    const layerEnabledCallback = useCallback((e) => {
        layerEnabledRef.current = e.target.checked;
        setLayerEnabled(layerEnabledRef.current);

        if (e.target.checked) {
            setFootprintsEnabled(false);
            historicalFootprintsCallback(false, name);
        } else {
            //setFootprintsEnabled(footprintsEnabledRef.current);
            //historicalFootprintsCallback(footprintsEnabledRef.current, name);
        }

        enableLayerCallback(layerEnabledRef.current, name);
    }, [layerEnabled]);

    const footprintsCallback = useCallback((e) => {
        footprintsEnabledRef.current = e.target.checked;

        setFootprintsEnabled(e.target.checked);
        if (e.target.checked) {
            setLayerEnabled(false);
            enableLayerCallback(false, name);
        } else {
            //setLayerEnabled(layerEnabledRef.current);
            //enableLayerCallback(layerEnabledRef.current, name);
        }

        historicalFootprintsCallback(e.target.checked, name);
    }, [footprintsEnabled]);

    const tracksEnabledCallback = useCallback((e) => {
        tracksEnabledRef.current = e.target.checked

        setTracksEnabled(tracksEnabledRef.current)
        if (!tracksEnabledRef.current) {
            footprintsCallback(e);
        }
        historicalTracksCallback(e.target.checked, name);

    }, [tracksEnabled, footprintsEnabled]);

    const sliderCallback = useCallback((e) => {

        if (name === 'HazardA') {
            setEventYearsA(e.target.value);
        } else if (name === 'HazardB') {
            setEventYearsB(e.target.value);
        }

        eventYearSliderCallback(e.target.value, name);
    }, [eventYearsA, eventYearsB]);

    return (
        <Box sx={{ flexGrow: 1}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={9}>
                            <Typography variant="h6">
                                Layer
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <FormGroup >
                                <FormControlLabel
                                    control={<Switch checked={layerEnabled} onChange={(e) => layerEnabledCallback(e)} size={"small"} />}
                                    label="Enable"
                                    labelPlacement="end"
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PerilSelect name={name} enabled={layerEnabled} selectLayerCallback={selectLayerCallback}/>
                </Grid>
                <Grid item xs={12}>
                    <EpochSelect name={name} enabled={layerEnabled} selectLayerCallback={selectLayerCallback}/>
                </Grid>
                <Grid item xs={12}>
                    <ReturnPeriodSelect name={name} enabled={layerEnabled} selectLayerCallback={selectLayerCallback}/>
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={11}>
                            <Typography variant="h6">
                                Events
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <HazardInfoIcon />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={11}>
                            <FormGroup>
                                <FormControlLabel control={<Switch onChange={(e) => tracksEnabledCallback(e)} />} label="Show Historical Tracks" />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={1}>
                                <HistoricalTracksInfoIcon />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={11}>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch disabled={!tracksEnabled} checked={footprintsEnabled} onChange={(e) => footprintsCallback(e)} />}
                                    label="Show Historical Footprints"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={1}>
                            <HistoricalFootprintsInfoIcon />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography gutterBottom>
                        Season
                    </Typography>
                    <Slider
                        value={name === 'HazardA' ? eventYearsA : eventYearsB}
                        disabled={!tracksEnabled}
                        step={1}
                        onChange={(e) => sliderCallback(e)} 
                        marks={marks}
                        min={1980}
                        max={2022}
                        valueLabelDisplay="auto"
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {(
            <Box sx={{ p: 3 }}>
                <Typography>{children}</Typography>
            </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ControlPanel(props) {

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    console.log(props)
    console.log(newValue)
    setValue(newValue);
  };

  // Use this to maintain state of tab https://codesandbox.io/s/material-demo-nxsfv?file=/demo.js
  return (
    <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Hazard A" {...a11yProps(0)} />
                <Tab label="Hazard B" {...a11yProps(1)} />
            </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
            <HazardLayerSelect name={'HazardA'} {...props} />
        </TabPanel>
        <TabPanel value={value} index={1}>
            <HazardLayerSelect name={'HazardB'} {...props} />
        </TabPanel>
    </Box>
  );
}
